const icons = {
  Replies: "",
  Article: "",
  "No image": "",
  "Plus-background": "",
  "Staff-background": "",
  "Vendor-background": "",
  "Premium-icon": "",
  "Plus-icon": "",
  "Staff-icon": "",
  "Vendor-icon": "",
};

// HACK: get icons html from other instances on the page that already rendered them
// this avoids having to pass them through from the templater to the preact components
export function getIcon(icon: keyof typeof icons) {
  icons[icon] =
    icons[icon] ||
    document.querySelector(
      `#home-preact-icons > svg.california-icon[aria-label="${icon}"]`
    )?.outerHTML ||
    "";
  return icons[icon];
}
