import { XF } from "../../XF";
import {
  DESKTOP_1054,
  DESKTOP_1600,
  MOBILE_732,
  TABLET_900,
  VISIBLE_FEATURED_SLOTS_ON_MOBILE,
  VISIBLE_FEATURED_SLOTS_ON_TABLET,
  getDeviceWidth,
} from "../../common/helper";

/**
 * Filters featured threads from thread results
 */
function getFeaturedThreadsToFilter(featuredThreads: string[]) {
  const deviceWidth = getDeviceWidth();

  // Only bother filtering the featured threads slots that are visible on current device
  if (
    deviceWidth <= MOBILE_732 ||
    (deviceWidth > TABLET_900 && deviceWidth < DESKTOP_1054)
  ) {
    return featuredThreads.slice(0, VISIBLE_FEATURED_SLOTS_ON_MOBILE);
  }
  if (
    deviceWidth < DESKTOP_1600 ||
    (deviceWidth <= TABLET_900 && deviceWidth >= MOBILE_732)
  ) {
    return featuredThreads.slice(0, VISIBLE_FEATURED_SLOTS_ON_TABLET);
  }
  return featuredThreads;
}

/** Retrieves list of hottest threads as ranked by vespa */
export async function getFeedContent(
  pageNumber = 1,
  featuredThreadsIds: string[] = [],
  useHtmlFeed = false
) {
  const nodeId = window.location.pathname.slice(0, -1).match(/[0-9]+$/);
  const result = await XF.ajax(
    "get",
    "/content-feed",
    {
      pageNumber,
      node_id: nodeId ? nodeId[0] : 0,
      html: useHtmlFeed ? true : undefined,
    },
    undefined,
    { skipDefault: true }
  );
  const usingHtml = Object.prototype.hasOwnProperty.call(result, "html");

  if (!usingHtml) {
    let threads: typeof result.message.threads = [];
    Object.keys(result.message.threads).forEach((key) =>
      threads.push(result.message.threads[key])
    );
    if (pageNumber === 1) {
      const toFilter = getFeaturedThreadsToFilter(featuredThreadsIds);
      threads = threads.filter((i) => !toFilter.includes(i.id.toString()));
    }
    return {
      data: threads,
      count: result.message.totalMatchedThreads,
      enableCF: result.message.enableCF,
      hasMore: result.message.hasMore,
    };
  }
  const div = document.createElement("div");
  div.innerHTML = result.html.content;
  const hasMore = (div.children[0] as HTMLElement)?.dataset.hasMore === "true";
  if (pageNumber === 1) {
    getFeaturedThreadsToFilter(featuredThreadsIds).forEach((i) => {
      div.querySelector(`li[data-thread-id="${i}"]`)?.remove();
    });
  }
  return { data: div.children[0]?.innerHTML, enableCF: false, hasMore };
}
