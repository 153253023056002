import { getIcon } from "../../getIcon";

export function Badge({
  badge,
  title,
}: {
  badge: "Plus" | "Premium" | "Staff" | "Vendor";
  title: string;
}) {
  return (
    <div
      class="badge-stack badge-stack-s"
      title={title}
      // this icon html is already on the page
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
        ${getIcon(`${badge === "Premium" ? "Plus" : badge}-background`).replace('class="', 'class="badge-outline ')}
        ${getIcon(`${badge === "Premium" ? "Plus" : badge}-background`).replace('class="', 'class="badge-background ')}
        ${getIcon(`${badge}-icon`).replace('class="', 'class="badge-foreground ')}`,
      }}
    />
  );
}
