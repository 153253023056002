import clsx from "clsx";
import { ComponentProps } from "preact";
import { useCallback, useRef, useState } from "preact/hooks";
import { gtmAvatar } from "../../../common/gtm-ids";
import { toGtmContext } from "../../../common/toGtmContext";
import { toSlug } from "../../../common/toSlug";
import { useActivate } from "../../../common/useActivate";
import { Badge } from "./Badge";

const badgeTypes = ["plus", "premium", "staff", "vendor"];

export function Avatar({
  mobileHidden = false,
  user: {
    avatar_type,
    avatar_url,
    avatar_styling: {
      bgColor = "var(--cali-c2)",
      color = "var(--text-primary)",
      innerContent,
    } = {},
    user_id,
    is_premium,
    username,
    profile_link,
    badge,
    badge_title,
  },
  size = "s",
  gtmContext,
}: {
  mobileHidden?: boolean;
  user: {
    profile_link?: string;
    badge?: string;
    badge_title?: string;
    avatar_type?: string;
    avatar_url?: string;
    avatar_styling?: {
      bgColor?: string;
      color?: string;
      innerContent?: string;
    };
    user_id?: number;
    is_premium?: boolean;
    username?: string;
  };
  size: "xxs" | "xs" | "s" | "m" | "l" | "o";
  gtmContext?: Record<string, string>;
}) {
  const [errored, setErrored] = useState(false);
  const onError = useCallback(() => {
    setErrored(true);
  }, []);
  // Use img tag only when the avatar type is appropriate and an image url is available
  const avatarInnerHtml =
    avatar_type !== "default" && avatar_url && !errored ? (
      // eslint-disable-next-line jsx-a11y/img-redundant-alt
      <img
        src={avatar_url}
        onError={onError}
        class="avatar-image avatar-image--gtm"
        alt={`Profile photo for ${username || "Guest"} user`}
        aria-label={`Profile photo for ${username || "Guest"} user`}
      />
    ) : (
      <div
        class="avatar-background"
        style={`background-color: ${bgColor}; color: ${color}`}
      >
        <span
          class={`avatar-image--gtm avatar-u${user_id}-s`}
          title={`Profile photo for ${username || "Guest"} user`}
          aria-label={`Profile photo for ${username || "Guest"} user`}
        >
          {innerContent || (username?.[0] || "?").toUpperCase()}
        </span>
      </div>
    );

  const avatarImage = (
    <span
      class={`avatar avatar--${size} avatar--default avatar--default--dynamic ${
        is_premium ? "premium premium-xs" : ""
      }`}
      data-user-id={user_id}
      style={`background-color: ${bgColor}; color: ${color}`}
    >
      {avatarInnerHtml}
    </span>
  );

  const link =
    profile_link ||
    (user_id && `/members/${username ? `${toSlug(username)}.` : ""}${user_id}`);

  const ref = useRef<HTMLAnchorElement>(null);
  useActivate(ref);

  return (
    <div
      class={clsx("avatar-container avatar-badge-wrapper", {
        "avatar-mobile-hidden": mobileHidden,
      })}
      data-gtm={gtmAvatar}
      data-gtm-context={toGtmContext({ user_id, ...gtmContext })}
    >
      {/* Include a href tag only if link is available */}
      {link ? (
        <a
          ref={ref}
          href={link}
          data-user-id={user_id}
          data-xf-init={user_id ? "member-tooltip" : undefined}
        >
          {avatarImage}
        </a>
      ) : (
        avatarImage
      )}
      {badge && badgeTypes.includes(badge) && (
        <Badge
          badge={
            `${badge.substring(0, 1).toUpperCase()}${badge.substring(1)}` as ComponentProps<
              typeof Badge
            >["badge"]
          }
          title={badge_title || ""}
        />
      )}
    </div>
  );
}
