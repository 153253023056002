import { useEffect, useMemo, useRef } from "preact/hooks";

export function PoLeaderboardItem() {
  const ref = useRef<HTMLDivElement>(null);

  const poLeaderboardContainer = useMemo(() => {
    return document.querySelector(
      "#home-preact-containers > #home-po-leaderboard-container"
    );
  }, []);

  useEffect(() => {
    const elRef = ref.current;
    if (!elRef || !poLeaderboardContainer) return;
    elRef.appendChild(poLeaderboardContainer);
  }, [poLeaderboardContainer]);

  return poLeaderboardContainer ? <div ref={ref} /> : null;
}
