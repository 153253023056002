import { getCFSrc } from "../../../common/helper";
import { getIcon } from "../../getIcon";

export function Thumbnail({
  thumbnail,
  imageLink,
  threadTitle,
  enableCF,
  isListing,
  isDeal,
}: {
  thumbnail: {
    image: string;
    isNsfw: boolean;
    imageCount: number;
  };
  imageLink: string;
  threadTitle: string;
  enableCF: boolean;
  isListing: boolean;
  isDeal: boolean;
}) {
  const { image, isNsfw, imageCount } = thumbnail;

  const displayImage = () => {
    return (
      <a href={imageLink}>
        <picture>
          <source
            media="(min-width: 651px)"
            srcset={enableCF ? getCFSrc(270, image) : image}
          />
          <source
            media="(min-width: 401px)"
            srcset={enableCF ? getCFSrc(1300, image) : image}
          />
          <div
            alt={threadTitle}
            class="thumbnail-feedItem-thread"
            style={`background-image: url('${
              enableCF ? getCFSrc(getThumbnailSize(), image) : image
            }');`}
          >
            {(imageCount > 1 || ((isDeal || isListing) && imageCount >= 1)) && (
              <span class="image-count-badge">
                <i
                  class="image-count-icon"
                  title="Image Count"
                  qid="search-result-image-count"
                />
                {imageCount}
              </span>
            )}
          </div>
        </picture>
      </a>
    );
  };

  const getThumbnailSize = () => {
    return window.innerWidth <= 650 ? 750 : 135;
  };

  const noImage = () => {
    return (
      (isListing || isDeal) && (
        <div
          class="listing-image no-image"
          // this icon html is already on the page
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: getIcon("No image") }}
        />
      )
    );
  };

  return (
    <div
      class={`thumbnail-feedItem-thread-container ${
        !image ? "thumbnail-feedItem-no-image" : ""
      } ${isNsfw ? "thumbnail-feedItem-hidden" : ""}`}
      qid="qid-thread-thumbnail"
    >
      {image && !isNsfw ? displayImage() : noImage()}
    </div>
  );
}
