import { ComponentProps } from "preact";
import { useEffect } from "preact/hooks";
import { Fragment } from "preact/jsx-runtime";
import { AdItem } from "../AdItem/AdItem";
import { FeedItem } from "../FeedItem/FeedItem";
import { PoLeaderboardItem } from "../PoLeaderboardItem/PoLeaderboardItem";

const DEFAULT_AD_POSITIONS = new Map([[10, { Mobile: "Homepage_Top_Mobile" }]]);

const DEFAULT_PO_LEADERBOARD_POSITION = 3;

const EXP_AD_POSITIONS = new Map([
  [
    6,
    {
      Desktop: "Homepage_In-Content_1",
      Mobile: "Homepage_Top_Mobile",
    },
  ],
  [
    12,
    {
      Desktop: "Homepage_In-Content_2",
      Mobile: "Homepage_In-Content_Mobile_2",
    },
  ],
  [
    18,
    {
      Desktop: "Homepage_In-Content_3",
      Mobile: "Homepage_In-Content_Mobile_3",
    },
  ],
  [
    24,
    {
      Desktop: "Homepage_In-Content_4",
      Mobile: "Homepage_In-Content_Mobile_4",
    },
  ],
  [
    30,
    {
      Desktop: "Homepage_In-Content_5",
      Mobile: "Homepage_In-Content_Mobile_5",
    },
  ],
  [
    36,
    {
      Desktop: "Homepage_In-Content_6",
      Mobile: "Homepage_In-Content_Mobile_6",
    },
  ],
  [
    42,
    {
      Desktop: "Homepage_In-Content_7",
      Mobile: "Homepage_In-Content_Mobile_7",
    },
  ],
]);

export function FeedItemLists({
  data,
  enableCF,
  showFeedAd,
  showAds,
}: {
  data: ComponentProps<typeof FeedItem>["threadInfo"];
  enableCF: boolean;
  showFeedAd: boolean;
  showAds: boolean;
}) {
  let activeAdConfig = showAds ? DEFAULT_AD_POSITIONS : new Map();
  activeAdConfig = showFeedAd ? EXP_AD_POSITIONS : activeAdConfig;

  useEffect(() => {
    // Fire a custom event indicating that the inline homepage ad has been loaded
    if (activeAdConfig.size > 0) {
      const event = new CustomEvent("newAdLoad", {
        detail: {},
      });
      document?.dispatchEvent(event);
    }
  }, [data, activeAdConfig.size]);

  return (
    <>
      {data.map((element, index) => (
        <Fragment key={element.id}>
          <FeedItem index={index} threadInfo={element} enableCF={enableCF} />
          {activeAdConfig.has(index + 1) && (
            <AdItem
              desktopId={activeAdConfig.get(index + 1).Desktop}
              mobileId={activeAdConfig.get(index + 1).Mobile}
            />
          )}
          {index + 1 === DEFAULT_PO_LEADERBOARD_POSITION && (
            <PoLeaderboardItem />
          )}
        </Fragment>
      ))}
    </>
  );
}
